import React, { forwardRef, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// NEW
import { Button, Divider, List, Modal, Progress, Result } from 'antd';

// css
import { css } from 'emotion';
import common from '../../style/common';
import colors from '../../style/colors';
import ScrollView from '../ui/ScrollView';
import InlineSpinner from '../ui/InlineSpinner';
import { toggleWorkHourModal } from '../../actions/uiActions';

import req from '../../utilities/request-utility';

const SelectAllComponent = (props, ref) => {
	const {
		selectAllAction,
		selectedTimeRecords = [],
		setSelectedTimeRecords = () => {},
		setIsProcessingAll = () => {},
		selectedUser = '',
		setShowSelectAllComponent = () => {},
		needsProcessingIsFetching = false,
		needsProcessingRefetch = () => {}
	} = props;

	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ maxWidth: '800px' });
	const modal = useSelector(({ ui }) => ui.workHourModal);

	const [successfullyProcessedData, setSuccessfullyProcessedData] = useState([]);
	const [submissionStatusData, setSubmissionStatusData] = useState({
		status: 'waiting',
		message: '',
		percentage: 0
	});

	const handleSubmit = async () => {
		try {
			//format data and sort by date
			const formattedApprovedRecords = selectedTimeRecords
				.map(record => ({
					recordID: record.id,
					type: record.type,
					data: record.data,
					date: record.data.date
				}))
				.sort((a, b) => {
					return new Date(a.date) - new Date(b.date);
				});

			let submittedRecords = 0;
			for (const record of formattedApprovedRecords) {
				const { data, type, recordID } = record;

				const currentDate =
					type === 'time'
						? data.date
						: `${moment(data.startDate).format('YYYY-MM-DD')} - ${moment(data.endDate).format(
								'YYYY-MM-DD'
						  )} `;

				setSubmissionStatusData({
					status: 'submitting',
					message: `Submitting ${currentDate} (ID:${record.recordID})`,
					percentage: Math.round((submittedRecords / formattedApprovedRecords.length) * 100)
				});

				await req().put(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${recordID}`, {
					...data,
					action: 'submit'
				});

				submittedRecords++;
				setSuccessfullyProcessedData(successfullyProcessedData => [
					...successfullyProcessedData,
					{
						date: currentDate,
						id: recordID,
						type
					}
				]);

				if (submittedRecords === formattedApprovedRecords.length) {
					setSubmissionStatusData({
						status: 'success',
						message: `Successfully submitted all Approved Records`,
						percentage: 100
					});
					setIsProcessingAll(false);
					needsProcessingRefetch();
				}
			}
		} catch (error) {
			console.log('error: ', error);
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			setSubmissionStatusData(submissionStatusData => ({
				...submissionStatusData,
				message: `${submissionStatusData.message.split(' ')[1]} ${
					submissionStatusData.message.split(' ')[2]
				} | Error: ${errorMessage}`,
				status: 'error'
			}));
			setIsProcessingAll(false);
		}
	};

	const handleRetry = async () => {
		await needsProcessingRefetch();
		setSubmissionStatusData({
			status: 'waiting',
			message: '',
			percentage: 0
		});

		//reset the approvedTimeRecord array
		if (selectAllAction === 'submit')
			setSelectedTimeRecords(
				selectedTimeRecords.filter(record => !successfullyProcessedData.includes(record.id))
			);
		setSuccessfullyProcessedData([]);
	};

	const handleDelete = async () => {
		try {
			//format data and sort by date
			const formattedApprovedRecords = selectedTimeRecords.map(record => ({
				recordID: record.id,
				type: record.type,
				data: record.data,
				date: record.data.date
			}));

			let submittedRecords = 0;
			for (const record of formattedApprovedRecords) {
				const { data, type, recordID } = record;

				const currentDate =
					type === 'time'
						? data.date
						: `${moment(data.startDate).format('YYYY-MM-DD')} - ${moment(data.endDate).format(
								'YYYY-MM-DD'
						  )} `;

				setSubmissionStatusData({
					status: 'submitting',
					message: `Deleting ${currentDate} (ID:${record.recordID})`,
					percentage: Math.round((submittedRecords / formattedApprovedRecords.length) * 100)
				});

				await req().delete(`semcotime/foremen-teams/users/${selectedUser}/work-hours/${recordID}`);

				submittedRecords++;
				setSuccessfullyProcessedData(successfullyProcessedData => [
					...successfullyProcessedData,
					{
						date: currentDate,
						id: recordID,
						type
					}
				]);

				if (submittedRecords === formattedApprovedRecords.length) {
					setSubmissionStatusData({
						status: 'success',
						message: `Successfully deleted selected record(s)`,
						percentage: 100
					});
					setIsProcessingAll(false);
					needsProcessingRefetch();
				}
			}
		} catch (error) {
			console.log('error: ', error);
			let errorMessage = 'Unspecified error';
			if (error && error.response && error.response.data) {
				if (error.response.data.message) errorMessage = error.response.data.message;
				else errorMessage = error.response.statusText;
			}

			setSubmissionStatusData(submissionStatusData => ({
				...submissionStatusData,
				message: `${submissionStatusData.message.split(' ')[1]} ${
					submissionStatusData.message.split(' ')[2]
				} | Error: ${errorMessage}`,
				status: 'error'
			}));
			setIsProcessingAll(false);
		}
	};

	const submitAllComponent = () => (
		<>
			{selectedTimeRecords.length == 0 && (
				<Result
					status="warning"
					title={'No Approved Records Selected'}
					subTitle={'Please select or approve records to continue'}
				/>
			)}

			{status === 'waiting' && !selectedTimeRecords.length == 0 && (
				<Result
					title={`Selected ${selectedTimeRecords.length} Approved Record/s`}
					subTitle={'Press "Submit All" to continue'}
					extra={
						<Button
							onClick={() => {
								setIsProcessingAll(true);
								handleSubmit();
							}}
							type="primary"
							key="console">
							Submit All
						</Button>
					}
				/>
			)}
			{status !== 'waiting' && !selectedTimeRecords.length == 0 && (
				<>
					<Result
						icon={status === 'submitting' ? <InlineSpinner /> : ''}
						status={resultIconStatus}
						subTitle={message}
						extra={
							<>
								<Progress percent={percentage} status={progressBarStatus} />
								{status === 'success' && (
									<Button
										type="primary"
										onClick={() => setShowSelectAllComponent(false)}
										key="console">
										OK
									</Button>
								)}
								{status === 'error' && (
									<Button
										type="primary"
										loading={needsProcessingIsFetching}
										onClick={handleRetry}
										key="console">
										Retry
									</Button>
								)}
							</>
						}
					/>

					<Divider orientation="left">Successfully Submitted</Divider>
					<List
						size="small"
						bordered
						dataSource={successfullyProcessedData}
						renderItem={item => (
							<List.Item extra={<h4>{`${capitalize(item.type)}`}</h4>}>
								<List.Item.Meta title={item.date} description={`[${item.id}]`} />
							</List.Item>
						)}
					/>
				</>
			)}
		</>
	);

	const deleteAllComponent = () => (
		<>
			{selectedTimeRecords.length == 0 && (
				<Result status="warning" title={'No Records Selected'} subTitle={'Please select records to continue'} />
			)}

			{status === 'waiting' && !selectedTimeRecords.length == 0 && (
				<Result
					title={`Selected ${selectedTimeRecords.length} Record/s`}
					subTitle={'Press "Delete All" to continue'}
					extra={
						<Button
							onClick={() => {
								setIsProcessingAll(true);
								handleDelete();
							}}
							type="primary"
							key="console">
							Delete All
						</Button>
					}
				/>
			)}
			{status !== 'waiting' && !selectedTimeRecords.length == 0 && (
				<>
					<Result
						icon={status === 'submitting' ? <InlineSpinner /> : ''}
						status={resultIconStatus}
						subTitle={message}
						extra={
							<>
								<Progress percent={percentage} status={progressBarStatus} />
								{status === 'success' && (
									<Button
										type="primary"
										onClick={() => setShowSelectAllComponent(false)}
										key="console">
										OK
									</Button>
								)}
								{status === 'error' && (
									<Button
										type="primary"
										loading={needsProcessingIsFetching}
										onClick={handleRetry}
										key="console">
										Retry
									</Button>
								)}
							</>
						}
					/>

					<Divider orientation="left">Successfully Deleted</Divider>
					<List
						size="small"
						bordered
						dataSource={successfullyProcessedData}
						renderItem={item => (
							<List.Item extra={<h4>{`${capitalize(item.type)}`}</h4>}>
								<List.Item.Meta title={item.date} description={`[${item.id}]`} />
							</List.Item>
						)}
					/>
				</>
			)}
		</>
	);

	const { message = '', status = 'waiting', percentage = 0 } = submissionStatusData;

	const progressBarStatus = { success: 'success', error: 'exception', submitting: 'active' }[status] || '';

	const resultIconStatus = { success: 'success', error: 'error' }[status] || '';

	const renderSubmitAllComponent = () => {
		return (
			<div className={componentStyles(isMobile)}>
				<ScrollView
					className={scrollViewStyles({
						topBarHeight: common.topBarHeight,
						bottomPanelHeight: 0
					})}>
					{selectAllAction === 'submit' && submitAllComponent()}
					{selectAllAction === 'delete' && deleteAllComponent()}
				</ScrollView>
			</div>
		);
	};

	return (
		<>
			{!isMobile && renderSubmitAllComponent()}

			{isMobile && (
				<Modal
					centered
					closable={false}
					footer={[
						<Button key="back" onClick={() => dispatch(toggleWorkHourModal(false))}>
							Cancel
						</Button>
					]}
					visible={modal}>
					{renderSubmitAllComponent()}
				</Modal>
			)}
		</>
	);
};

const scrollViewStyles = ({ bottomPanelHeight, topBarHeight }) => css`
	height: calc(96vh - ${topBarHeight}px - ${bottomPanelHeight}px);
	padding: 1rem;

	@media (max-width: 414px) {
		padding: unset;
	}
`;

const componentStyles = (primaryColor, isMobile) => css`
	background-color: ${colors.white};
	border-radius: 3px;
	border: ${isMobile ? 'none' : `1px ${colors.midGrey} solid`};
	border-left: ${isMobile ? 'none' : `3px ${primaryColor} solid`};
	padding: 0.65rem;
	margin-bottom: 10px;
`;

export default forwardRef(SelectAllComponent);
